import { Header } from '@ocenkatech/common/ui/Header';
import { Loader } from '@ocenkatech/common/ui/Loader';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

const userNavigation = [
    {
        name: 'Оценочная компания',
        href: '/settings/evaluating-company',
    },
    { name: 'Оценщики', href: '/settings/appraiser' },
    { name: 'Выйти', href: '/logout' },
];

export const MainLayout: React.FC = () => {
    return (
        <div className="h-full">
            <Header userMenu={userNavigation} />
            <Suspense
                fallback={
                    <div className="flex h-[calc(100vh-64px)] w-full items-center justify-center">
                        <Loader />
                    </div>
                }>
                <Outlet />
            </Suspense>
        </div>
    );
};

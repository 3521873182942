import { FC, PropsWithChildren } from 'react';
import { Outlet, RouteProps } from 'react-router-dom';

export const InnerLayout: FC<PropsWithChildren<RouteProps>> = ({
    children,
}) => {
    return (
        <>
            <main>
                <div className="mx-auto max-w-7xl px-4 py-6">
                    <div className="overflow-hidden rounded-lg border-b border-gray-200 shadow">
                        <Outlet />
                        {children}
                    </div>
                </div>
            </main>
        </>
    );
};

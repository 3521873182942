import { TOKEN_KEY } from '@ocenkatech/common/const';
import { getValueInStorage } from '@ocenkatech/common/lib';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Token = string | null;

type AuthState = {
    token: Token;
    user: string;
};

const token = getValueInStorage(TOKEN_KEY);

const slice = createSlice({
    name: 'auth',
    initialState: {
        token,
        user: '',
    },
    reducers: {
        setUser: (state, action: PayloadAction<string>) => {
            state.user = action.payload;
        },
    },
});

export const { setUser } = slice.actions;
export default slice.reducer;

export const currentUser = (state: { auth: AuthState }) => state.auth.user;

import { jwtDecode } from 'jwt-decode';
import { ReactElement } from 'react';
import { createBrowserRouter, Navigate, useLocation } from 'react-router-dom';
import { AppraiserOrder } from 'pages/appraiser-order';
import { LoginPage } from 'pages/login';
import { LogoutPage } from 'pages/logout';
import { MainPage } from 'pages/main';
import { SettingsAppraiserPage } from 'pages/settings/appraiser/';
import { SettingsEvaluatingCompanyPage } from 'pages/settings/evaluating-company';
import { useGetToken } from 'shared/lib/react/useGetToken';
import { InnerLayout } from './layouts/InnerLayout';
import { MainLayout } from './layouts/MainLayout';
import { JwtToken } from './types';

function GuestGuard({ children }: { children: ReactElement }) {
    const token = useGetToken();
    const location = useLocation();

    if (token) {
        const decodedToken = jwtDecode<JwtToken>(token);
        if (decodedToken.appraiser) return children;
    }

    return <Navigate to="/login" replace state={{ from: location }} />;
}

type AuthGuardProps = {
    children: ReactElement;
    toComponent?: ReactElement;
};

function AuthGuard({ children, toComponent }: AuthGuardProps) {
    const token = useGetToken();

    if (token) return toComponent ? toComponent : <LoginPage />;

    return children;
}

export const appRouter = createBrowserRouter([
    {
        path: '/',
        element: (
            <GuestGuard>
                <MainLayout />
            </GuestGuard>
        ),
        children: [
            {
                path: '/',
                element: <MainPage />,
            },
            {
                path: 'order/:id',
                element: (
                    <InnerLayout>
                        <AppraiserOrder />
                    </InnerLayout>
                ),
            },
            {
                path: 'settings',
                element: <InnerLayout />,
                children: [
                    {
                        path: 'evaluating-company',
                        element: <SettingsEvaluatingCompanyPage />,
                    },
                    { path: 'appraiser', element: <SettingsAppraiserPage /> },
                ],
            },
        ],
    },
    {
        path: '/login',
        element: (
            <AuthGuard>
                <LoginPage />
            </AuthGuard>
        ),
    },
    {
        path: '/logout',
        element: <LogoutPage />,
    },
    {
        path: '*',
        element: <Navigate to="/" />,
    },
]);
